export const CMS_MODULES = {
  ANCHOR_BUTTONS: 'anchor-buttons',
  BOARD_WITH_4_ICONS: 'board-with-4-icons',
  BOARD_WITH_6_ICONS: 'board-with-6-icons',
  BOARDS_WITH_FIELDS_BELOW: 'boards-with-fields-below',
  BOARDS_WITH_FIELDS_BELOW_STACK: 'boards-with-fields-below-stack',
  BOX_AND_2_PRODUCTS: 'box-and-2-products',
  BOX_AND_4_PRODUCTS: 'box-and-4-products',
  BOX_WITH_MARGIN: 'box-with-margin',
  BOX_WITHOUT_MARGIN: 'box-without-margin',
  CLY_PRODUCTS: 'cly-products',
  CLY_PRODUCTS_CATEGORY_TABS: 'cly-products-category-tabs',
  CLY_4_PRODUCTS_CATEGORY_TABS: 'cly-four-products-category-tabs',
  COMBO_MINI_SLIDER: 'combo-mini-slider-plus-box-all-fields',
  DCW_PRODUCTS: 'dcw-products',
  FAQS: 'faqs',
  FAQS_WITH_LINK: 'faqs-with-link',
  FOUR_PRODUCTS_CAROUSEL: 'four-products-carousel',
  FREQUENTLY_BOUGHT_TOGETHER: 'frequentlyboughttogether',
  FULL_WIDTH_BANNER: 'full-width-banner',
  FULL_WIDTH_BANNER_WITH_SHADOW: 'full-width-banner-with-shadow',
  FULL_WIDTH_BANNER_COLLECTION_PROMO: 'fullwidth-banner-collection-promo',
  FULL_WIDTH_BANNER_COLLECTION_PROMO_MULTI: 'fullwidth-banner-collection-promo-multiple',
  FULL_WIDTH_PROMO_BANNER: 'fullwidth-promo-banner',
  FULL_WIDTH_PROMO_BANNER_2: 'fullwidth-promo-banner-2',
  FULL_WIDTH_PROMO_BANNER_3: 'fullwidth-promo-banner-3',
  FULL_WIDTH_PROMO_BANNER_4: 'fullwidth-promo-banner-4',
  GRID_OF_BOARDS_2_COLS: 'grid-of-boards-two-columns',
  GRID_OF_BOARDS_3_COLS: 'grid-of-boards-three-columns',
  GRID_OF_BOARDS_4_COLS: 'grid-of-boards-four-columns',
  GRID_OF_PRODUCTS: 'grid-of-products',
  INSTAGRAM_CAROUSEL: 'instagram-carousel',
  LANDSCAPE_BANNER: 'landscape-banner',
  LANDSCAPE_BANNER_COLLECTION_PROMO: 'landscape-banner-collection-promo',
  LANDSCAPE_BANNER_COLLECTION_PROMO_MULTI: 'landscape-banner-collection-promo-multiple',
  LANDSCAPE_PROMO_BANNER: 'landscape-promo-banner',
  LANDSCAPE_PROMO_BANNER_2: 'landscape-promo-banner-2',
  LANDSCAPE_PROMO_BANNER_3: 'landscape-promo-banner-3',
  MINI_SLIDER: 'mini-slider',
  MOD_BOX_WITH_MARGIN: 'mod-box-with-margin',
  MOD_BOX_WITH_MARGIN_PRODUCTS: 'mod-box-with-margin-products',
  MOD_BOX_WITHOUT_MARGIN: 'mod-box-without-margin',
  MOD_BOX_WITHOUT_MARGIN_PRODUCTS: 'mod-box-without-margin-products',
  PDP_BANNER: 'pdp-banner',
  PLAIN_SLIDER: 'plain-slider',
  QUERY_LIST: 'query-list',
  RECENTLY_VIEWED: 'recently-viewed',
  SIZE_GUIDE: 'size-guide',
  SQUARE_BOARDS_WITH_SPLIT: 'square-boards-with-split',
  SQUARE_BOARDS_WITHOUT_SPLIT: 'square-boards-without-split',
  SQUAT_BANNER: 'squat-banner',
  TABLE_COMPLEX: 'complex-table',
  TABLE_INDEX: 'index-table',
  TABLE_SIMPLE: 'simple-table',
  TABLE_STAR: 'star-table',
  TEXT_MODULE: 'text-module',
  TEXT_MODULE_1_DEFAULT: 'text-module-1-default',
  THREE_PRODUCTS_CAROUSEL: 'three-products-carousel',
  THREE_ITEM_BOARD_CAROUSEL: 'three-item-board-carousel',
  TOP_PAGE_BANNER: 'top-page-banner',
  TOP_PAGE_BANNER_COLLECTION_PROMO: 'top-page-banner-collection-promo',
  TOP_PAGE_BANNER_COLLECTION_PROMO_MULTI: 'top-page-banner-collection-promo-multiple',
  TOP_PAGE_PROMO: 'top-page-promo',
  TOP_PAGE_PROMO_2: 'top-page-promo-2',
  TOP_PAGE_PROMO_3: 'top-page-promo-3',
  TWO_COL_BANNER_LG: 'two-column-banner-lg',
  TWO_ITEM_BOARD_CAROUSEL: 'two-item-board-carousel',
  VALUE_PROPOSITION_BANNER: 'value-proposition-banner',
  WALL_OF_BRANDS: 'wall-of-brands',
  WALL_OF_BRANDS_WITH_TABS: 'wall-of-brands-with-tabs',
  YOU_MAY_ALSO_LIKE: 'you-may-also-like',
  NEWS_LETTER_SUBSCRIBE: 'newsletter-subscribe-banner',
  NEWS_LETTER_LANDING_PAGE: 'newsletter-landing-page',
  MENTION_ME_LANDING_PAGE: 'mention-me',
  PROMO_STRIP: 'promo-strip',
}

export const DEFAULT_VIEW_TYPE = 'default'

export const CRITICAL_INDEX = 3
