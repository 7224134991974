import React from 'react';

interface TextToHtmlProps {
    text: string;
}

export const TextToHtml: React.FC<TextToHtmlProps> = ({ text }) => {
    const htmlText = text.replace(/\n/g, '<br />');
    return (
        <span dangerouslySetInnerHTML={{ __html: htmlText }} ></span>
    );
};