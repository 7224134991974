import { IVideoMedia, TMedia } from '@typesApp/cmsPlacement/Media'
import { isPictureMedia, isVideoMedia } from '@typesApp/teaser'
import clsx from 'clsx'
import { VideoPlayer } from '../VideoPlayer'
import { Picture } from './Picture'
import { ImageCropTypes, cmsImageCrops } from '@constants/ui'
import { getSrcSetsImageFromCms } from '@utils/url'
import { useSelector } from 'react-redux'
import { hostnameUrlsSelector } from '@redux/selectors/site'
import { useContext } from 'react'
import { ContextWrapperData, PlacementContext } from '../PlacementContextWrapper/PlacementContextWrapper'
import { PlacementContextType } from '../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { isLXTeaser } from '@typesApp/cmsPlacement/Placement'
import { getDataElementId } from '@utils/common'
import { Anchor } from '@components/UI-CSS/Anchor'
import { getFormattedToLink } from './helpers'
import { Seo } from '@components/Cms/CmsComponents/CmsCommonMedia/Seo'
import { Container } from '@components/Cms/CmsComponents/CmsCommonMedia'

type CmsMedia = {
  media: TMedia[] | undefined
  cropType: ImageCropTypes
  hasOverlay?: boolean
  onSetPlayingId?: (id?: string) => void
  playingId?: string
  canVideoBeClickable?: boolean
  lazy?: boolean
  alt?: string
  videoClassName?: string
}

export const CmsMedia: React.FC<CmsMedia> = props => {
  const { media, hasOverlay, onSetPlayingId, playingId, canVideoBeClickable, lazy, cropType, alt, videoClassName } =
    props
  const hostnameUrls = useSelector(hostnameUrlsSelector)
  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data } = context as ContextWrapperData<PlacementContextType>
  const teaser = data?.placement?.items?.find(isLXTeaser)

  if (!media || !cropType) return null

  const [mediaFile] = media || []
  const isVideo = isVideoMedia(mediaFile)
  const isPicture = isPictureMedia(mediaFile)

  let pictureSrcSets = getSrcSetsImageFromCms(
    cropType as string,
    isVideo ? mediaFile?.picture?.uriTemplate : mediaFile?.uriTemplate ?? '',
    hostnameUrls
  )

  if (media?.length > 1) {
    const mobileMediaFile = media[1]
    const { mobile, tabletP, md, sm, xs, src } = getSrcSetsImageFromCms(
      cropType as string,
      isVideo ? (mobileMediaFile as IVideoMedia)?.picture?.uriTemplate : mobileMediaFile?.uriTemplate ?? '',
      hostnameUrls
    )
    pictureSrcSets = {
      ...pictureSrcSets,
      mobile,
      tabletP,
      md,
      sm,
      xs,
      src,
    }
  }

  const { mobile, tabletL, tabletP, deskL, deskS, xxl, xl, lg, md, sm, xs, src } = pictureSrcSets

  const { toLink } = getFormattedToLink(teaser)
  const viewtype = data?.placement?.viewtype ?? 'default'
  const teaserName = teaser?.name?.replaceAll(' ', '_') ?? ''
  const cmsImageCommonProps = {
    'aria-label': `Placement_${teaserName} IMG link. Teaser №${0}`,
    'data-element-id': getDataElementId(viewtype, teaser?.placementCounter),
  }

  const PictureContent: React.FC = () => {
    return (
      <>
        {!lazy ? (
          <Seo
            tabletL={tabletL}
            tabletP={tabletP}
            deskL={deskL}
            deskS={deskS}
            mobile={mobile}
            xxl={xxl}
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
          />
        ) : null}
        <Container
          fullHeight={false}
          hasHoverImage={false}
          withProducts={cropType === 'BOX_WITH_2_PRODUCTS' || cropType === 'BOX_WITH_4_PRODUCTS'}
        >
          <Picture
            alt={isPicture && !!mediaFile.alt ? mediaFile.alt : alt}
            deskL={deskL}
            deskS={deskS}
            tabletL={tabletL}
            tabletP={tabletP}
            xxl={xxl}
            xl={xl}
            lg={lg}
            md={md}
            sm={sm}
            xs={xs}
            src={src ?? mobile}
            isLazy={lazy}
            crops={cmsImageCrops?.[cropType] ?? {}}
          />
        </Container>
      </>
    )
  }

  return (
    <div
      className={clsx(
        'media-wrapper',
        {
          'has-overlay': hasOverlay,
          'is-video': isVideo,
        },
        isVideo && !!videoClassName ? videoClassName : ''
      )}
    >
      {isVideo && (
        <VideoPlayer
          lazyload={false}
          media={media as IVideoMedia[]}
          onSetPlayingId={onSetPlayingId}
          playingId={playingId}
          canVideoBeClickable={canVideoBeClickable}
        />
      )}
      {isPicture ? (
        toLink ? (
          <Anchor href={toLink} className="cmsMediaLink" {...cmsImageCommonProps} variant="noAnimation">
            <PictureContent />
          </Anchor>
        ) : (
          <PictureContent />
        )
      ) : null}
    </div>
  )
}
