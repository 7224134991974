import { createContext, useMemo } from 'react'
import { IAlgoliaHit, IProduct, IServerProduct } from '@typesApp/product'

export type ProductContextType = {
  product: IProduct | IServerProduct | IAlgoliaHit | undefined
}

type ProductProviderProps = { productData: ProductContextType } & React.PropsWithChildren

export const ProductContext = createContext<ProductContextType>({ product: undefined })

export const ProductContextProvider: React.FC<ProductProviderProps> = props => {
  const { children, productData } = props
  const value = useMemo(() => productData, [productData])

  return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>
}
