import React, { CSSProperties, MouseEvent, useMemo } from 'react'
import Head from 'next/head'
import { useTranslation } from 'next-i18next'
import { each } from 'lodash-es'
import { useTheme } from '@mui/material'
import queryString from 'query-string'

import config from '../../configs/config.base'
import { useSite } from '../../foundation/hooks/useSite'
import { Attachment, IProduct } from '../../types/product'

import mockedData from './mocked-attachments'
import { StyledProductVideo, ProductVideoContainer } from './ProductImage.styles'

export const PDP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE = '1.0'
export const PDP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE = '2.0'
export const PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE = '2'
export const PLP_PRODUCT_IMAGE_FRONT_VIEW_SEQUENCE = '1'
export const PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE = '1'
export const PLP_PRODUCT_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE = '2'

export const IMAGE_WITH_SHADOW_QUARTER_ANGLE_ID_INCLUDES = '_shad__qt'

export type PictureType =
  | 'quarter'
  | 'front'
  | 'lateral'
  | 'closed front'
  | 'back'
  | 'alternative'
  | 'folding'
  | 'folding group'
  | 'group'
  | 'OnModel'
  | 'adv'

type ProductImageUsage = 'PDP' | 'PLP' | 'Thumbnail' | 'PDPStickyBar'

export type ProductImageProps = {
  alt?: string
  preventlazyLoad?: boolean
  attachments?: Attachment[]
  attr?: object
  draggable?: boolean
  mpolicy?: string
  noImageStyle?: CSSProperties
  pictureType?: PictureType
  sequence?: string
  style?: any
  usage?: ProductImageUsage
  width?: number
  srcsetmap?: Record<number, string>
  active?: boolean
  autoPlay?: boolean
  onClick?: () => void
  onMouseMove?: (e: MouseEvent<HTMLImageElement>) => void
  backgroundColor?: string
  partialProduct?: IProduct
  lazy?: boolean
  loadOnActive?: boolean
  preload?: boolean
}

const ProductImage = (props: ProductImageProps) => {
  const {
    alt,
    attachments,
    width = 600,
    pictureType,
    sequence,
    preventlazyLoad = false,
    usage = 'PDP',
    attr,
    noImageStyle,
    draggable,
    srcsetmap,
    backgroundColor,
    active = false,
    lazy = true,
    loadOnActive = false,
    preload = true,
    partialProduct,
    ...rest
  } = props

  const theme = useTheme()
  const { mySite: site } = useSite()
  const { t } = useTranslation()
  const isRetina = typeof window !== 'undefined' && devicePixelRatio > 1
  const damDomain: string = site.xStoreCfg
    ? site.xStoreCfg['damDomain'] || config.defaultDamDomain
    : config.defaultDamDomain

  let attachmentList: Attachment[] = []
  if (!attachments && config.useMockedAttachments !== 'false') {
    attachmentList = mockedData.attachments
  } else {
    attachmentList = attachments ? [...attachments] : []
  }

  const attachment =
    attachmentList &&
    attachmentList.find(attachment => {
      return (
        attachment?.usage?.toLowerCase() === usage.toLowerCase() &&
        (pictureType ? attachment.name.toLowerCase() === pictureType.toLowerCase() : true) &&
        (sequence ? parseInt(attachment.sequence) === parseInt(sequence) : true)
      )
    })

  const url404 = '/images/common/404.svg'
  const imagePath = attachment ? damDomain + attachment.attachmentAssetPathRaw : url404

  const getImageUrl = (width: number) => {
    const wid = (usage === 'PDPStickyBar' || usage === 'PLP' && isRetina) ? width * 2 : width
    return queryString.stringifyUrl({
      url: imagePath.toString(),
      query: {
        ...attr,
        impolicy: 'CLE_resize',
        wid,
        bgc: backgroundColor || theme.palette.background.light.primary,
      },
    })
  }

  const productImageSrcSets = (params: Record<number, string>): string => {
    let results = ''
    each(params, (resolution, imageWidth) => {
      const path = getImageUrl(Number(imageWidth) || width)
      results = results.concat(`${path} ${resolution}, `)
    })

    return results || ''
  }

  const altToSet = useMemo<string>(() => alt ?? imagePath, [alt, imagePath])

  const isVideo = /(\.mp4)$/.test(imagePath)
  const shouldLazyLoad = imagePath !== url404 && !preventlazyLoad && lazy

  const getAltLabel = (imagePath: string, url404: string) => {
    return imagePath === url404 ? t('ImageNotAvailable.title') : altToSet
  }

  if (isVideo) {
    const shouldDisplayVideo = !loadOnActive || (loadOnActive && active)

    return (
      <ProductVideoContainer {...rest}>
        {shouldDisplayVideo && (
          <StyledProductVideo
            src={imagePath}
            autoPlay={false}
            loop={true}
            muted={true}
            play={active}
            playsInline={true}
            preload="metadata"
            controls={false}
          />
        )}
      </ProductVideoContainer>
    )
  }

  return (
    <>
      {!shouldLazyLoad && preload && (
        <Head>
          <link
            key={imagePath}
            rel="preload"
            as="image"
            imageSrcSet={!!srcsetmap ? productImageSrcSets(srcsetmap) : ''}
            href={getImageUrl(width)}
            fetchpriority="high"
          />
        </Head>
      )}
      <img
        alt={getAltLabel(imagePath, url404)}
        crossOrigin="anonymous"
        src={getImageUrl(width)}
        loading={shouldLazyLoad ? 'lazy' : 'eager'}
        srcSet={!!srcsetmap ? productImageSrcSets(srcsetmap) : ''}
        {...rest}
        width="100%"
        style={noImageStyle || {}}
        draggable={draggable}
      />
    </>
  )
}

export default ProductImage
