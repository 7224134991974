import ClientOnlyPortal from '@components/ClientOnlyPortal/ClientOnlyPortal'
import { Button } from '@components/UI-CSS/Button'
import { ICMLXTeaser } from '@typesApp/cmsPlacement/CMLXTeaser'
import clsx from 'clsx'
import { CSSProperties, useState } from 'react'
import { CmsCta } from '../CmsCta'
import { CmsMedia } from '../CmsMedia'
import { ImageCrops } from '../CmsMedia/types'
import style from './styles/index.module.scss'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import HTMLReactParser from 'html-react-parser'

type CmsCtaModal = {
  text: string
  ctaStyle: CSSProperties
  teaser?: ICMLXTeaser
}

const imageCrops: ImageCrops = {
  xxl: {
    crop: 'landscape_ratio40x13',
    width: 2880,
    height: 936,
  },
  xl: {
    crop: 'landscape_ratio40x13',
    width: 2560,
    height: 936,
  },
  lg: {
    crop: 'landscape_ratio32x13',
    width: 1843,
    height: 749,
  },
  md: {
    crop: 'landscape_ratio192x71',
    width: 1382,
    height: 511,
  },
  sm: {
    crop: 'landscape_ratio192x71',
    width: 1382,
    height: 511,
  },
}

export const CmsCtaModal = ({ dataElementId, text, ctaStyle, teaser }) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isMounted, setIsMounted] = useState<boolean>(false)
  const { teaserLXCallToActionSettings, teaserTitle1, teaserText1 } = teaser
  const handleCloseModal = () => {
    setIsMounted(false)
  }

  const handleOpenModal = () => {
    setShowModal(true)
    setIsMounted(true)
  }

  const stopPropagation = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
  }

  const handleAnimationEnd = () => {
    if (!isMounted) setShowModal(false)
  }

  return (
    <>
      <Button data-element-id={dataElementId} {...ctaStyle} onClick={handleOpenModal}>
        {HTMLReactParser(text)}
      </Button>
      {showModal && (
        <ClientOnlyPortal selector={'#vmPortal'}>
          <div
            className={clsx(style.overlay, { [style.mounting]: isMounted, [style.unmounting]: !isMounted })}
            onClick={handleCloseModal}
            onAnimationEnd={handleAnimationEnd}
          >
            <div className={style.modal}>
              <button className={style.close} aria-label="close" onClick={handleCloseModal}>
                <SVGIcon library="close" name="close" />
              </button>
              <h1 className={style['title']}>{teaserTitle1}</h1>
              {teaser?.media && <CmsMedia media={teaser?.media} cropType="MODAL_INFO_ARTICLE" />}
              <div className={style.body} dangerouslySetInnerHTML={{ __html: teaserText1 }} />
              {teaserLXCallToActionSettings &&
                teaserLXCallToActionSettings.map((actionSettings, index) => (
                  <div
                    className={style['button-container']}
                    key={`${actionSettings.callToActionText}_${index}`}
                    onClick={stopPropagation}
                  >
                    <CmsCta dataElementId={dataElementId} teaserCtaSetting={actionSettings} />
                  </div>
                ))}
            </div>
          </div>
        </ClientOnlyPortal>
      )}
    </>
  )
}
