import React from 'react'

import { Logo } from '@components/UI'
import { SVGExtendedProps, SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { CMS_ICONS } from '@components/UI-CSS/SVGIcon/constants'
import styles from './styles/CmsIcon.module.scss'

export type CmsIconProps = {
  teaserIcon?: string
} & React.BaseHTMLAttributes<SVGElement> &
  SVGExtendedProps

export const CmsIcon: React.FC<CmsIconProps> = props => {
  const { teaserIcon, size = 48, ...rest } = props

  switch (teaserIcon) {
    // TODO: refactor clearly logo
    case CMS_ICONS.ICON_CLEARLY_LOGO:
      return <Logo />
    case CMS_ICONS.ICON_CLEARLY_LOGO_LIGHT:
      return <Logo type="lightcolor" {...rest} />
    case CMS_ICONS.ICON_CLEARLY_LOGO_SMALL:
      return <Logo height={32} width={80} {...rest} />
    case CMS_ICONS.ICON_CLEARLY_LOGO_SMALL_LIGHT:
    case CMS_ICONS.ICON_CLEARLY_LOGO_LIGHT_SMALL:
      return <Logo height={32} width={80} type="lightcolor" {...rest} />

    case CMS_ICONS.ICON_ADDRESS:
    case CMS_ICONS.ICON_AFTER_CARE:
      return <SVGIcon library="misc1" name="address" size={size} {...rest} />
    case CMS_ICONS.ICON_BAG_CHECKOUT:
      return <SVGIcon library="global" name="bag-checkout" size={size} {...rest} />
    case CMS_ICONS.ICON_BABY_SMILE:
      return <SVGIcon library="misc1" name="baby-smile" size={size} {...rest} />
    case CMS_ICONS.ICON_CALENDAR:
      return <SVGIcon library="global" name="calendar" size={size} {...rest} />
    case CMS_ICONS.ICON_SHIPMENT_FREQUENCY:
      return <SVGIcon library="global" name="calendar-thin" size={size} {...rest} />
    case CMS_ICONS.ICON_CHAT_SMALL:
      return <SVGIcon library="misc1" name="chat" size={24} {...rest} />
    case CMS_ICONS.ICON_CHAT:
    case CMS_ICONS.ICON_CHAT_LARGE:
      return <SVGIcon library="misc1" name="chat" size={size} {...rest} />
    case CMS_ICONS.ICON_CHOOSE:
      return <SVGIcon library="misc1" name="choose" size={size} {...rest} />
    case CMS_ICONS.ICON_CLEARLY_BASICS_LOGO:
      return <SVGIcon library="misc1" name="clearly-basics" className={styles.clearlyBasics} {...rest} />
    case CMS_ICONS.ICON_CLEARLY_GAMER_LOGO:
      return <SVGIcon library="misc1" name="clearly-gamer" className={styles.clearlyGamer} {...rest} />
    case CMS_ICONS.ICON_CLEARLY_JUNIOR_LOGO:
      return <SVGIcon library="misc1" name="clearly-junior" className={styles.clearlyJunior} {...rest} />
    case CMS_ICONS.ICON_CLOCK:
      return <SVGIcon library="misc1" name="clock" size={size} {...rest} />
    case CMS_ICONS.ICON_CONTACT_LENS_FINGER:
      return <SVGIcon library="misc1" name="contact-lens-finger" size={size} {...rest} />
    case CMS_ICONS.ICON_CREDIT_CARD:
      return <SVGIcon library="misc1" name="credit-card" size={size} {...rest} />
    case CMS_ICONS.ICON_CREDIT_CARD_SECURE:
      return <SVGIcon library="misc3" name="credit-card-secure" size={size} {...rest} />
    case CMS_ICONS.ICON_SECURE_CHECKOUT_SMALL:
      return <SVGIcon library="misc3" name="credit-card-secure" size={24} {...rest} />
    case CMS_ICONS.ICON_DEREK_CARDIGAN_LOGO:
      return <SVGIcon library="misc2" name="derek-cardigan" className={styles.derekCardigan} {...rest} />
    case CMS_ICONS.ICON_DROP:
      return <SVGIcon library="misc1" name="drop" size={size} {...rest} />
    case CMS_ICONS.ICON_CONTACT_LENS:
    case CMS_ICONS.ICON_EVERCLEAR_CONTACTS:
    case CMS_ICONS.ICON_CONTACT_LENSES:
      return <SVGIcon library="filter" name="contact-lens" size={size} {...rest} />
    case CMS_ICONS.ICON_EVERYDAY_COMFORT:
      return <SVGIcon library="misc3" name="everyday-comfort" size={size} {...rest} />
    case CMS_ICONS.ICON_EYE_CARE:
      return <SVGIcon library="filter" name="eye-care" size={size} {...rest} />
    case CMS_ICONS.ICON_EYE:
      return <SVGIcon library="global" name="eye" size={size} {...rest} />
    case CMS_ICONS.ICON_EYE_SMALL:
      return <SVGIcon library="global" name="eye" size={24} {...rest} />
    case CMS_ICONS.ICON_EYEWEAR_RANGE:
      return <SVGIcon library="misc1" name="eyewear-range" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_GLASSES_SMILE:
      return <SVGIcon library="misc1" name="face-glasses-smile" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_OVAL_THICK:
      return <SVGIcon library="misc1" name="face-oval-thick" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_OVAL:
      return <SVGIcon library="misc1" name="face-oval" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_RECTANGLE_SLIM:
      return <SVGIcon library="misc1" name="face-rectangle-slim" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_RECTANGLE_THICK:
      return <SVGIcon library="misc1" name="face-rectangle-thick" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_RECTANGLE:
      return <SVGIcon library="misc1" name="face-rectangle" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_ROUND_THICK:
      return <SVGIcon library="misc1" name="face-round-thick" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_ROUND:
      return <SVGIcon library="misc1" name="face-round" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_SLIM:
      return <SVGIcon library="misc1" name="face-slim" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_SQUARE:
      return <SVGIcon library="misc1" name="face-square" size={size} {...rest} />
    case CMS_ICONS.ICON_FACE_TRIANGLE:
      return <SVGIcon library="misc1" name="face-triangle" size={size} {...rest} />
    case CMS_ICONS.ICON_FRAME_ADVISOR:
      return <SVGIcon library="global" name="frame-genius" size={size} {...rest} />
    case CMS_ICONS.ICON_FREE_RETURN:
      return <SVGIcon library="misc1" name="free-return" size={size} {...rest} />
    case CMS_ICONS.ICON_GLASSES_CASE:
      return <SVGIcon library="filter" name="glasses-case" size={size} {...rest} />
    case CMS_ICONS.ICON_GLASSES_CROSS:
      return <SVGIcon library="filter" name="glasses-cross" size={size} {...rest} />
    case CMS_ICONS.ICON_GLASSES:
      return <SVGIcon library="filter" name="glasses" size={size} {...rest} />
    case CMS_ICONS.ICON_GLASSES_WITH_HEART_SMALL:
      return <SVGIcon library="misc3" name="glasses-with-heart" size={24} {...rest} />
    case CMS_ICONS.ICON_GLASSES_ON_HAND_ICON_SMALL:
      return <SVGIcon library="misc3" name="glass-on-hand" size={24} {...rest} />
    case CMS_ICONS.ICON_GLASSES_ON_FACE_ICON_SMALL:
      return <SVGIcon library="misc3" name="glass-on-face" size={24} {...rest} />
    case CMS_ICONS.ICON_GLOBAL_CARE:
      return <SVGIcon library="misc1" name="global-care" size={size} {...rest} />
    case CMS_ICONS.ICON_GREEN_SHIPPING:
      return <SVGIcon library="misc2" name="green-shipping" size={size} {...rest} />
    case CMS_ICONS.ICON_HEART_EMPTY:
      return <SVGIcon library="favorite" name="heart-slim" size={size} {...rest} />
    case CMS_ICONS.ICON_HEART_FILLED:
      return <SVGIcon library="favorite" name="fav-heart" size={size} {...rest} />
    case CMS_ICONS.ICON_HEART_ON_HAND_SMALL:
      return <SVGIcon library="misc3" name="heart-on-hand" size={24} {...rest} />
    case CMS_ICONS.ICON_INFO:
      return <SVGIcon library="validation" name="info" size={size} {...rest} />
    case CMS_ICONS.ICON_JOSEPH_MARC_LOGO:
      return <SVGIcon library="misc2" name="joseph-marc" className={styles.josephMarc} {...rest} />
    case CMS_ICONS.ICON_KAM_DHILLON_LOGO:
      return <SVGIcon library="misc2" name="kam-dhillon" className={styles.kamDhillon} {...rest} />
    case CMS_ICONS.ICON_LEADING_TECH:
      return <SVGIcon library="misc3" name="leading-tech" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_CLEANER:
      return <SVGIcon library="lens" name="cleaner" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_ANTI_STATIC:
      return <SVGIcon library="lens" name="antistatic" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_SCRATCH_RESISTANT:
      return <SVGIcon library="lens" name="scratch-resistant" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_WATER_REPELLENT:
      return <SVGIcon library="lens" name="water-repellent" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_SMUDGE_REPELLENT:
      return <SVGIcon library="lens" name="smudge-repellent" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_ANTI_REFLECTION:
      return <SVGIcon library="lens" name="antireflection" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_SELECT_PROGRESSIVES:
      return <SVGIcon library="lens" name="progressive" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_COMPUTER_LIGHT_FILTER:
      return <SVGIcon library="lens" name="computer-light-filter" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_PRIMARY:
      return <SVGIcon library="lens" name="primary" size={size} {...rest} />
    case CMS_ICONS.ICON_EXTRA_CLEANER:
      return <SVGIcon library="misc3" name="extra-cleaning" size={size} {...rest} />
    case CMS_ICONS.ICON_EXTENDED_DURABILITY:
      return <SVGIcon library="feature" name="extended-durability" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_GLASS:
    case CMS_ICONS.ICON_LENS_GLASS_SECONDARY:
      return <SVGIcon library="lens" name="glass" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_TRANSITIONS_ICON:
      return <SVGIcon library="misc3" name="lens-sun" size={size} {...rest} />
    case CMS_ICONS.ICON_LOG_IN:
      return <SVGIcon library="global" name="login" size={size} {...rest} />
    case CMS_ICONS.ICON_LUXURY_GLASSES:
      return <SVGIcon library="misc2" name="luxury-glasses" size={size} {...rest} />
    case CMS_ICONS.ICON_MAIN_CENTRAL_LOGO:
      return <SVGIcon library="misc2" name="main-central" className={styles.mainCentral} {...rest} />
    case CMS_ICONS.ICON_MAGNIFYING_GLASS:
      return <SVGIcon library="misc2" name="magnifying-glass" size={size} {...rest} />
    case CMS_ICONS.ICON_MAGNIFY_EYE:
      return <SVGIcon library="misc3" name="magnify-eye" size={size} {...rest} />
    case CMS_ICONS.ICON_MITIGATE_SENSITIVITY:
    case CMS_ICONS.ICON_LIGHT_SENSITIVITY:
      return <SVGIcon library="misc3" name="mitigate-sensitivity" size={size} {...rest} />
    case CMS_ICONS.ICON_MASK:
    case CMS_ICONS.ICON_MASK_LARGE:
      return <SVGIcon library="misc2" name="mask" size={size} {...rest} />
    case CMS_ICONS.ICON_OAKLEY_GLASS:
      return <SVGIcon library="misc2" name="oakley-glass" size={size} {...rest} />
    case CMS_ICONS.ICON_OFFER:
      return <SVGIcon library="misc2" name="offer" size={size} {...rest} />
    case CMS_ICONS.ICON_ONE_SIGHT:
      return <SVGIcon library="misc3" name="one-sight" className={styles.oneSight} {...rest} />
    case CMS_ICONS.ICON_OPTICIAN:
      return <SVGIcon library="misc2" name="optician" size={size} {...rest} />
    case CMS_ICONS.ICON_OPTOMETRIST:
      return <SVGIcon library="misc2" name="optometrist" size={size} {...rest} />
    case CMS_ICONS.ICON_PADLOCK:
      return <SVGIcon library="misc2" name="padlock" size={size} {...rest} />
    case CMS_ICONS.ICON_PERSPECTIVE_LOGO:
      return <SVGIcon library="misc2" name="perspective" className={styles.perspective} {...rest} />
    case CMS_ICONS.ICON_PILOT_DARK_GLASSES:
      return <SVGIcon library="misc2" name="pilot-dark-glasses" size={size} {...rest} />
    case CMS_ICONS.ICON_PILOT_GLASSES_METER:
      return <SVGIcon library="misc2" name="pilot-glasses-meter" size={size} {...rest} />
    case CMS_ICONS.ICON_PRESCRIPTION:
    case CMS_ICONS.ICON_PRESCRIPTION_SMALL:
    case CMS_ICONS.ICON_PRESCRIPTION_LARGE:
      return <SVGIcon library="misc2" name="prescription" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_POLARIZED:
      return <SVGIcon library="misc2" name="polarized" size={size} {...rest} />
    case CMS_ICONS.ICON_LENS_POLARIZED_PRIMARY:
      return <SVGIcon library="misc3" name="polarized-primary" size={size} {...rest} />
    case CMS_ICONS.ICON_PRICE_MATCH_GUARANTEE:
    case CMS_ICONS.ICON_EVERYDAY_AFFORDABLE:
      return <SVGIcon library="misc2" name="price-match-guarantee" size={size} {...rest} />
    case CMS_ICONS.ICON_CHECKMARK_SMALL:
      return <SVGIcon library="misc2" name="price-match-guarantee" size={24} {...rest} />
    case CMS_ICONS.ICON_QUESTION:
      return <SVGIcon library="lens" name="glasses" size={size} {...rest} />
    case CMS_ICONS.ICON_RAYBAN_GLASS:
      return <SVGIcon library="misc2" name="rayban-glass" size={size} {...rest} />
    case CMS_ICONS.ICON_REINCARNATE_LOGO:
      return <SVGIcon library="misc2" name="reincarnate" className={styles.reincarnate} {...rest} />
    case CMS_ICONS.ICON_REPLACE:
      return <SVGIcon library="misc2" name="replace" size={16} {...rest} />
    case CMS_ICONS.ICON_RETINAL_SCREENING:
      return <SVGIcon library="misc2" name="retinal-screening" size={size} {...rest} />
    case CMS_ICONS.ICON_ROUND_GLASSES:
    case CMS_ICONS.ICON_ROUND_GLASSES_LARGE:
      return <SVGIcon library="frame" name="round" size={size} {...rest} />
    case CMS_ICONS.ICON_ROUND_GLASSES_SMALL:
      return <SVGIcon library="frame" name="round" size={24} {...rest} />
    case CMS_ICONS.ICON_SANITATION_STATION:
      return <SVGIcon library="misc3" name="sanitation-station" size={size} {...rest} />
    case CMS_ICONS.ICON_SEARCH:
      return <SVGIcon library="global" name="search" size={size} {...rest} />
    case CMS_ICONS.ICON_SHOPPING:
      return <SVGIcon library="global" name="bag" size={size} {...rest} />
    case CMS_ICONS.ICON_SMOOTH_RECTANGLE_GLASSES:
      return <SVGIcon library="misc2" name="smooth-rectangle-glasses" size={size} {...rest} />
    case CMS_ICONS.ICON_SOCIAL_DISTANCE:
      return <SVGIcon library="misc2" name="social-distance" size={24} {...rest} />
    case CMS_ICONS.ICON_SOCIAL_INSTAGRAM:
      return <SVGIcon library="social" name="social-instagram" size={size} {...rest} />
    case CMS_ICONS.ICON_SOCIAL_INSTAGRAM_BOLD:
      return <SVGIcon library="social" name="social-instagram-bold" size={size} {...rest} />
    case CMS_ICONS.ICON_SOCIAL_TIKTOK_BOLD:
      return <SVGIcon library="social" name="social-tiktok-bold" size={size} {...rest} />
    case CMS_ICONS.ICON_SOCIAL_FACEBOOK_BOLD:
      return <SVGIcon library="social" name="social-facebook-bold" size={size} {...rest} />
    case CMS_ICONS.ICON_SOCIAL_TWITTER_BOLD:
      return <SVGIcon library="social" name="social-twitter-bold" size={size} {...rest} />
    case CMS_ICONS.ICON_STORE_MANAGER:
      return <SVGIcon library="misc2" name="store-manager" size={24} {...rest} />
    case CMS_ICONS.ICON_STORES:
      return <SVGIcon library="global" name="stores" size={24} {...rest} />
    case CMS_ICONS.ICON_STORES_LARGE:
      return <SVGIcon library="global" name="stores" size={size} {...rest} />
    case CMS_ICONS.ICON_SUBSCRIBE_SAVE:
      return <SVGIcon library="misc3" name="savings" size={size} {...rest} />
    case CMS_ICONS.ICON_SUNNY:
    case CMS_ICONS.ICON_CLEAR_VISION_BRIGHT:
      return <SVGIcon library="misc3" name="sunny" size={size} {...rest} />
    case CMS_ICONS.ICON_SMILING_FACE:
      return <SVGIcon library="misc3" name="smiling-face" size={size} {...rest} />
    case CMS_ICONS.ICON_DIRECTION:
      return <SVGIcon library="misc3" name="direction" size={size} {...rest} />
    case CMS_ICONS.ICON_TEMPLE_SIZE:
      return <SVGIcon library="misc2" name="temple-size" size={size} {...rest} />
    case CMS_ICONS.ICON_TEST:
      return <SVGIcon library="misc2" name="test" size={size} {...rest} />
    case CMS_ICONS.ICON_TRANSITIONS_SEAMLESS:
    case CMS_ICONS.ICON_TRANSITIONS_PROTECTION:
      return <SVGIcon library="misc1" name="transitions-seamless" size={120} {...rest} />
    case CMS_ICONS.ICON_TRANSITIONS_STYLE:
      return <SVGIcon library="misc1" name="transitions-style" size={120} {...rest} />
    case CMS_ICONS.ICON_TRIANGLE_DANGER:
      return <SVGIcon library="account" name="triangle-danger" size={size} {...rest} />
    case CMS_ICONS.ICON_TRY_ON_SMALL:
      return <SVGIcon library="misc3" name="try-on-anywhere" size={size} {...rest} />
    case CMS_ICONS.ICON_RETURN:
      return <SVGIcon library="account" name="return" size={size} {...rest} />
    case CMS_ICONS.ICON_SHOW:
      return <SVGIcon library="account" name="show" size={size} {...rest} />
    case CMS_ICONS.ICON_TEXT_MODULE_EYEGLASSES:
    case CMS_ICONS.ICON_EYEGLASSES_DARK:
      return <SVGIcon library="misc2" name="text-module-eyeglasses" size={size} {...rest} />
    case CMS_ICONS.ICON_TRUCK_SMALL:
    case CMS_ICONS.ICON_TRUCK_LARGE:
    case CMS_ICONS.ICON_SPEEDY_SHIPPING_SMALL:
      return <SVGIcon library="account" name="shipping" size={size} {...rest} />
    case CMS_ICONS.ICON_SHIPPING_DELAY:
      return <SVGIcon library="misc3" name="shipping-delay" size={size} {...rest} />
    case CMS_ICONS.ICON_PHONE_SMALL:
      return <SVGIcon library="misc2" name="call" size={24} {...rest} />
    case CMS_ICONS.ICON_PHONE_LARGE:
      return <SVGIcon library="misc2" name="call" size={size} {...rest} />
    case CMS_ICONS.ICON_EMAIL_SMALL:
      return <SVGIcon library="misc2" name="mail" size={24} {...rest} />
    case CMS_ICONS.ICON_EMAIL_LARGE:
      return <SVGIcon library="misc2" name="mail" size={size} {...rest} />
    case CMS_ICONS.ICON_CUSTOMER_CARE:
      return <SVGIcon library="misc2" name="customer-care" size={size} {...rest} />
    case CMS_ICONS.ICON_CUSTOMER_CARE_SMALL:
      return <SVGIcon library="misc2" name="customer-care" size={24} {...rest} />
    case CMS_ICONS.ICON_UV_PROTECT:
    case CMS_ICONS.ICON_UVA_UVB_PROTECTION:
      return <SVGIcon library="misc3" name="insurance" size={size} {...rest} />
    default:
      return null
  }
}
